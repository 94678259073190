<template>
  <div>
    <Header
      imgUrl="header-nutricionclinicapediatrica.png"
      :classes="{ texto: 'align-middle', img: 'w-100' }"
      :texto="$t('productos.header_ncp')"
    />

    <div class="container my-4">
      <div class="row">
        <div class="col-md-4 col-xs-12 pe-0">
          <img
            src="@/assets/images/productos/Lata_JuniorSemielemental.webp"
            class="img-fluid pb-2"
          />
          <div class="d-flex flex-column">
            <Sabor color="#e6e7e8" :texto="$t('productos.saborNeutro')" />

            <span class="mt-2">
              {{ $t("productos.presentacionLata", { gr: 400 }) }}
            </span>
          </div>
        </div>
        <div class="col-md-8 col-xs-12 ps-0 pl-mobile">
          <div class="row">
            <p
              class="pt-3"
              v-html="$t('productos.sustentaJuniorSemielemental.descripcion')"
            ></p>
          </div>

          <CaracteristicasNutricionales
            :columnas="2"
            :caracteristicas="caracteristicas"
          />

          <div class="row mt-3">
            <div
              class="col-md-5 d-flex justify-content-around align-items-left"
            >
              <div>{{ $t("productos.alergenos") }}</div>
              <div>
                <img
                  :src="getIcon('icono-leche.png')"
                  class="img-fluid alergenosHeight"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid ntr-collapse py-3 my-2">
      <div class="container d-flex justify-content-between">
        <h5>{{ $t("tablasNutricionales.titulo") }}</h5>
        <button
          class="btn btn-sm btn-azul"
          @click="toggleTablaNutricional = !toggleTablaNutricional"
        >
          {{ toggleTablaNutricional ? "+" : "-" }}
        </button>
      </div>
    </div>

    <div class="container" :class="{ oculto: toggleTablaNutricional }">
      <TableSustentaJuniorSemielemental />
    </div>

    <div class="container-fluid ntr-collapse py-3 my-2">
      <div class="container d-flex justify-content-between">
        <h5>{{ $t("productos.modoPreparacion.titulo") }}</h5>
      </div>
    </div>

    <div class="container">
      <ModoPreparacion
        :texto="$t('productos.modoPreparacion.texto')"
        :textoOrientativo="
          $t('productos.sustentaJuniorSemielemental.textoOrientativo')
        "
        :tabla="this.$i18n.locale == 'en' ? tablaEn : tablaEs"
        :medida="$t('productos.modoPreparacion.medida', { gr: '5,1', producto: 'Sustenta® Junior Semielemental'})"
      />
    </div>

    <div class="container-fluid ntr-collapse bg-azul py-3 my-2">
      <div class="container">
        <h5>{{ $t("productos.otrosProductos") }}</h5>
      </div>
    </div>

    <CardsRecomendados
      :productos="['nutricion-clinica-pediatricos', 'modulos-nutricionales']"
    />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import CaracteristicasNutricionales from "@/components/CaracteristicasNutricionales.vue";
import es from "@/locales/es.json";
import en from "@/locales/en.json";
import TableSustentaJuniorSemielemental from "@/components/tablas/TableSustentaJuniorSemielemental.vue";
import ModoPreparacion from "@/components/ModoPreparacion.vue";
import Sabor from "@/components/elementos/Sabor.vue";
import CardsRecomendados from "@/components/CardsRecomendados.vue";

export default {
  name: "SustentaJuniorSemielemental",
  components: {
    Header,
    CaracteristicasNutricionales,
    TableSustentaJuniorSemielemental,
    ModoPreparacion,
    CardsRecomendados,
    Sabor,
  },
  data() {
    return {
      toggleTablaNutricional: true,
      caracteristicas: {
        en: en.productos.sustentaJuniorSemielemental.caracteristicas,
        es: es.productos.sustentaJuniorSemielemental.caracteristicas,
      },
      tablaEs: es.productos.sustentaJuniorSemielemental.tablaPreparacion,
      tablaEn: en.productos.sustentaJuniorSemielemental.tablaPreparacion,
    };
  },
  methods: {
    getIcon(fileName) {
      return require("../../assets/images/icons/" +
        this.$i18n.locale +
        "/" +
        fileName);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>