<template>
  <table class="tabla-nutricional table table-sm table-striped table-hover table-responsive mt-5">
    <thead class="text-uppercase text-center">
      <tr>
        <th scope="col">{{ $t('tablasNutricionales.energia_y_nutrientes') }}</th>
        <th scope="col">{{ $t('tablasNutricionales.unidades') }}</th>
        <th scope="col" v-html="$t('tablasNutricionales.cada100Gpolvo_br')"></th>
        <th scope="col">{{ $t('tablasNutricionales.cada100ml' , {pv:"20,4"} ) }}</th>
      </tr>
    </thead>
    <tbody class="text-center">
      <tr>
        <td>{{ $t('tablasNutricionales.valor_energetico') }}</td>
        <td>Kcal</td>
        <td>434</td>
        <td>89</td>
      </tr>
      <tr>
        <td>&nbsp;</td>
        <td>Kj</td>
        <td>1823</td>
        <td>372</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.carbohidratos') }}</td>
        <td>g</td>
        <td>66</td>
        <td>13,2</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.proteinas') }}</td>
        <td>g</td>
        <td>11</td>
        <td>2,3</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_totales') }}</td>
        <td>g</td>
        <td>14</td>
        <td>2,9</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_saturadas') }}</td>
        <td>g</td>
        <td>7,6</td>
        <td>1,5</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_monoinsaturadas') }}</td>
        <td>g</td>
        <td>5,7</td>
        <td>1,2</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_poliinsaturadas') }}</td>
        <td>g</td>
        <td>0,65</td>
        <td>0,13</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.colesterol') }}</td>
        <td>mg</td>
        <td>0</td>
        <td>0</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_trans') }}</td>
        <td>g</td>
        <td>0</td>
        <td>0</td>
      </tr>
      <tr class="resaltado">
        <td>{{ $t('tablasNutricionales.fibra_alimentaria') }}</td>
        <td>g</td>
        <td>0</td>
        <td>0</td>
      </tr>
      <tr class="resaltado">
        <th scope="row" colspan="4">{{ $t('tablasNutricionales.minerales') }}</th>
      </tr>
      <tr></tr>
      <tr>
        <td>{{ $t('tablasNutricionales.sodio') }}</td>
        <td>mg</td>
        <td>300</td>
        <td>61</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.potasio') }}</td>
        <td>mg</td>
        <td>527</td>
        <td>108</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.cloro') }}</td>
        <td>mg</td>
        <td>484</td>
        <td>99</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.calcio') }}</td>
        <td>mg</td>
        <td>293</td>
        <td>60</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.fosforo') }}</td>
        <td>mg</td>
        <td>258</td>
        <td>53</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.magnesio') }}</td>
        <td>mg</td>
        <td>20</td>
        <td>4,1</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.hierro') }}</td>
        <td>mg</td>
        <td>7,0</td>
        <td>1,4</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.zinc') }}</td>
        <td>mg</td>
        <td>1,8</td>
        <td>0,37</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.cobre') }}</td>
        <td>mcg</td>
        <td>250</td>
        <td>51</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.manganeso') }}</td>
        <td>mg</td>
        <td>0,40</td>
        <td>0,08</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.fluor') }}</td>
        <td>mg</td>
        <td>0,30</td>
        <td>0,06</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.molibdeno') }}</td>
        <td>mcg</td>
        <td>34</td>
        <td>6,9</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.selenio') }}</td>
        <td>mcg</td>
        <td>10</td>
        <td>2,0</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.cromo') }}</td>
        <td>mcg</td>
        <td>20</td>
        <td>4,1</td>
      </tr>
      <tr class="resaltado">
        <td>{{ $t('tablasNutricionales.iodo') }}</td>
        <td>mcg</td>
        <td>40</td>
        <td>8,2</td>
      </tr>
      <tr class="resaltado">
        <th scope="row" colspan="4">{{ $t('tablasNutricionales.vitaminas') }}</th>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_a') }}</td>
        <td>mcg RE</td>
        <td>200</td>
        <td>41</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_d') }}</td>
        <td>mcg</td>
        <td>11</td>
        <td>2,1</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_e') }}</td>
        <td>mg alfa TE</td>
        <td>12</td>
        <td>2,4</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_k') }}</td>
        <td>mcg</td>
        <td>30</td>
        <td>6,1</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.tiamina_vit_b_1') }}</td>
        <td>mg</td>
        <td>0,70</td>
        <td>0,14</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.riboflavina_vit_b_2') }}</td>
        <td>mg</td>
        <td>0,70</td>
        <td>0,14</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.niacina_vit_b_3') }}</td>
        <td>mg</td>
        <td>3,0</td>
        <td>0,61</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.acido_pantotenico') }}</td>
        <td>mg</td>
        <td>2,9</td>
        <td>0,59</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.piridoxina_vit_b_6') }}</td>
        <td>mg</td>
        <td>0,70</td>
        <td>0,14</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.acido_folico') }}</td>
        <td>mcg</td>
        <td>75</td>
        <td>15</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.cianocobalamina_vit_b_12') }}</td>
        <td>mcg</td>
        <td>1,3</td>
        <td>0,27</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.biotina') }}</td>
        <td>mcg</td>
        <td>18</td>
        <td>3,7</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_c') }}</td>
        <td>mg</td>
        <td>40</td>
        <td>8,2</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.colina') }}</td>
        <td>mg</td>
        <td>135</td>
        <td>28</td>
      </tr>
      <tr>
        <td colspan="4">{{ $t('tablasNutricionales.osmolaridad', {osmolaridad: 200, solutos:219}) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "TableSustentaJuniorSemielemental",
};
</script>
